<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">브랜드톡 템플릿 관리</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">브랜드톡 템플릿 목록</h3>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
              <base-input additional-classes="width-100">
                <el-select
                  class="select-primary pagination-select"
                  v-model="pagination.perPage"
                  placeholder="Per page">
                  <el-option
                    class="select-primary"
                    v-for="item in pagination.perPageOptions"
                    :key="item"
                    :label="item"
                    :value="item">
                  </el-option>
                </el-select>
              </base-input>

              <div class="form-inline" style="margin-bottom: 1.5rem;">
                <div class="col-lg-4 pr-0">
                  <el-select v-model="search.condition"
                             filterable
                             placeholder="검색 조건">
                    <el-option v-for="option in selectOptions"
                               :key="option.label"
                               :label="option.label"
                               :value="option.value">
                    </el-option>
                  </el-select>
                </div>
                <div class="col-lg-8 pl-1 pr-0">
                  <base-input group :input-group-classes="'width-100p'">
                    <input type="text" class="form-control" placeholder="검색어 입력" aria-label="검색어 입력" aria-describedby="button-addon2"
                           v-model="search.keyword"
                           @keydown.enter="getList(1)">
                    <div class="input-group-append">
                      <button class="btn btn-outline-primary" type="button" @click="getList(1)">검색</button>
                    </div>
                  </base-input>
                </div>
              </div>
            </div>

            <el-table :data="templateList"
                      :header-row-class-name="'thead-light'"
                      :header-cell-style="{'font-weight':'bold','font-size':'0.85rem'}"
                      :empty-text="'등록된 템플릿이 없습니다.'"
                      class="table-responsive table-flush">
              <el-table-column label="번호" min-width="5%">
                <template slot-scope="scope">
                  {{ pagination.total - (pagination.currentPage-1)*pagination.perPage-scope.$index }}
                </template>
              </el-table-column>

              <el-table-column prop="templateCode" label="템플릿코드" min-width="15%"></el-table-column>
              <el-table-column prop="templateName" label="템플릿명" min-width="15%">
                <template slot-scope="scope">
                  <router-link :to="'view/' + scope.row.kkoSeqno + '?referer=' + $route.path + params" class="link_detail link_txt emph_g">
                    {{ scope.row.templateName }}
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column prop="templateContent" label="메시지내용" min-width="30%">
                <template slot-scope="scope">
                  <router-link :to="'view/' + scope.row.kkoSeqno + '?referer=' + $route.path + params" class="link_detail link_txt emph_g">
                    {{ scope.row.templateContent }}
                  </router-link>
                </template>
              </el-table-column>
              <el-table-column prop="messageType" label="메시지유형" min-width="8%">
                <template slot-scope="scope">{{ scope.row.messageType | messageTypeFilter }}</template>
              </el-table-column>
              <el-table-column prop="unsubscribeContent" label="무료수신거부번호" min-width="12%">
                <template slot-scope="scope">{{ scope.row.unsubscribeContent }}</template>
              </el-table-column>
              <el-table-column prop="createdAt" label="등록일자" min-width="10%">
                <template slot-scope="scope">{{ scope.row.createdAt }}</template>
              </el-table-column>
            </el-table>
          </div>

          <div slot="footer" class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <div class="">
              <p class="card-category">
                총 <span class="text-danger font-weight-bold">{{ total }}</span> 건
              </p>
            </div>

            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
              @input="getList">
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapGetters } from 'vuex';
  import { Table, TableColumn, Select, Option } from 'element-ui';
  import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
  import { USER_API_PREFIX, RESULT_CODE, STATUS_CODE } from '@/shared/util/const';
  import swal from 'sweetalert2';

  export default {
    mixins: [
      commPaginationMixin
    ],
    components: {
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    computed: {
      ...mapGetters({
        session: 'SessionStore/getSession'
      }),
      params() {
        return '&page=' + this.pagination.currentPage + '&perPage=' + this.pagination.perPage + '&condition=' + this.search.condition + '&keyword=' + this.search.keyword;
      }
    },
    filters: {
      messageTypeFilter: value => {
        let name = '';
        switch (value) {
          case 'I':
            name = '이미지형';
            break;
          case 'W':
            name = '와이드형';
            break;
        }
        return name;
      }
    },
    created() {
      if(this.$route.query.page) this.pagination.currentPage = Number(this.$route.query.page);
      if(this.$route.query.perPage) this.pagination.perPage = Number(this.$route.query.perPage);
      if(this.$route.query.condition) this.search.condition = this.$route.query.condition;
      if(this.$route.query.keyword) this.search.keyword = this.$route.query.keyword;

      // 목록 조회
      this.getList(this.pagination.currentPage);
    },
    data() {
      return {
        templateList: [],
        search: {
          condition: 'TEMPLATE_NAME',
          keyword: ''
        },
        selectOptions: [
          {
            label: '템플릿명',
            value: 'TEMPLATE_NAME'
          },
          {
            label: '템플릿코드',
            value: 'TEMPLATE_CODE'
          }
        ],
      };
    },
    methods: {
      /**
       * 목록 조회
       */
      getList(page) {
        let pageNo = page ? page : this.pagination.currentPage;
        this.$axios.get(`${USER_API_PREFIX}/brandtalk/template`, {
          params: {
            page: pageNo,
            rows: Number(this.pagination.perPage),
            searchCondition: this.search.condition,
            searchKeyword: this.search.keyword
          }
        }).then(res => {
          this.templateList = res.data.result.list; // 목록정보 세팅
          this.pagination.currentPage = pageNo; // 페이지 번호 세팅
          this.pagination.total = res.data.result.total; // 전체페이지수 세팅
        });
      },
    }
  };
</script>
<style>
  .no-border-card .card-footer{
    border-top: 0;
  }
</style>
