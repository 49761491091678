<template>
  <div class="content">
    <base-header type="kakao" class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-12 col-12">
          <h6 class="h2 text-kakao d-inline-block mb-0">브랜드톡 템플릿 정보</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-breadcrumb/>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" footer-classes="pb-2">
          <template slot="header">
            <h3 class="mb-0 d-inline">브랜드톡 템플릿 정보</h3><span class="text-danger pl-2">(템플릿상태가 대기인 경우에만 수정 가능합니다.)</span>
            <div class="float-right" v-if="form.status === 'R'">
              <base-button type="danger" block @click="processRemove" :disabled="!(form.status === 'R')">삭제</base-button>
            </div>
            <div class="float-right mr-4" v-if="form.status === 'R'">
              <base-button type="primary" block @click="processModify" :disabled="!(form.status === 'R')">수정</base-button>
            </div>
            <div class="float-right mr-2">
              <base-button type="info" @click="goBack">목록으로</base-button>
            </div>
          </template>
          <div>
            <form>
              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">등록자</label>
                <div class="col-md-2">
                  <div class="form-group"><p class="form-control" style="border: 0">{{ form.regBy }}</p></div>
                </div>
                <label class="col-md-2 col-form-label form-control-label text-center">등록일</label>
                <div class="col-md-2">
                  <div class="form-group"><p class="form-control" style="border: 0">{{ form.createdAt }}</p></div>
                </div>
                <label class="col-md-2 col-form-label form-control-label text-center">템플릿상태</label>
                <div class="col-md-2">
                  <div class="form-group"><p class="form-control" style="border: 0">{{ form.status | templateStatusFilter }}</p></div>
                </div>
              </div>

              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">발신프로필</label>
                <div class="col-md-10">
                  <base-input v-model="form.channelId"
                              ref="channelId"
                              name="발신프로필"
                              :error="_getError('발신프로필')"
                              v-validate="'required'">
                    <el-select v-model="form.channelId"
                               filterable
                               placeholder="발신프로필 선택">
                      <el-option v-for="option in senderKeyList"
                                 :key="option.channelId"
                                 :label="`${option.sendProfileName} (${option.channelId})`"
                                 :value="option.channelId">
                      </el-option>
                    </el-select>
                  </base-input>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">템플릿코드</label>
                <div class="col-md-10">
                  <base-input placeholder="템플릿코드"
                              readonly
                              v-model="form.templateCode"
                  >
                  </base-input>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">메시지유형 <span class="text-danger">*</span></label>
                <div class="col-md-10">
                  <base-input>
                    <base-radio v-model="form.messageType" class="mt-1 mb-2" name="I" :inline="true">이미지형</base-radio>
                    <base-radio v-model="form.messageType" class="mt-1 mb-2" name="W" :inline="true">와이드형</base-radio>
                  </base-input>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">템플릿명 <span class="text-danger">*</span></label>
                <div class="col-md-10">
                  <base-input placeholder="템플릿명 입력 (최대 200자)"
                              v-model="form.templateName"
                              :maxlength="200"
                              name="템플릿명"
                              :error="_getError('템플릿명')"
                              v-validate="'required|max:200'"
                  >
                  </base-input>
                </div>
              </div>

              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">이미지 <span class="text-danger">*</span></label>
                <div class="col-md-2 pb-3">
                  <dropzone-file-upload ref="dropzone" :options="dropzoneOptions" :image-data="imageData" acceptedFile="image/jpeg, image/jpg, image/png" v-model="image"/>
                </div>
                <div class="col-md-8">
                  <p class="text-danger font-weight-bold mb-0" style="font-size:0.8rem;">- 권장 이미지 크기는 이미지형은 800px * 400 px, 와이드형은 800px * 600 px 입니다.</p>
                  <p class="text-danger font-weight-bold mb-0" style="font-size:0.8rem;">- 이미지 파일은 용량이 2MB 이하인 jpg, png 파일만 등록 가능합니다.</p>
                  <p class="text-danger font-weight-bold mb-0" style="font-size:0.8rem;">- 이미지 파일은 가로 500px 미만 또는 가로:세로 비율이 2:1 미만 또는 3:4 초과시 업로드 불가합니다.</p>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">이미지 링크 <span class="text-danger">*</span></label>
                <div class="col-md-10">
                  <base-input placeholder="이미지링크에는 'http://', 'https://'가 포함되어야 합니다."
                              v-model="form.imageLink"
                              :maxlength="200"
                              name="이미지링크"
                              :error="_getError('이미지링크')"
                              v-validate="'required|max:200'"
                  >
                  </base-input>
                </div>
              </div>

              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">템플릿내용 <span class="text-danger">*</span></label>
                <div class="col-md-10">
                  <base-input v-if="form.messageType === 'I'"
                              v-model="form.templateContent"
                              name="템플릿내용"
                              :error="_getError('템플릿내용')"
                              v-validate="'required|max:400'">
                    <textarea v-model="form.templateContent"
                              ref="templateContent"
                              class="form-control"
                              rows="6"
                              resize="none"
                              placeholder="템플릿 내용은 한/영 구분없이 이미지형은 400자까지 입력 가능합니다."
                              @keyup="keyupContent($event)"
                              @input="typing"
                    ></textarea>
                    <p class="float-right text-sm">{{templateContentLength(400)}}</p>
                  </base-input>
                  <base-input v-else
                              v-model="form.templateContent"
                              name="템플릿내용"
                              :error="_getError('템플릿내용')"
                              v-validate="'required|max:76'">
                    <textarea v-model="form.templateContent"
                              ref="templateContent"
                              class="form-control"
                              rows="6"
                              resize="none"
                              placeholder="템플릿 내용은 한/영 구분없이 와이드형은 76자까지 입력 가능합니다."
                              @keyup="keyupContent($event)"
                              @input="typing"
                    ></textarea>
                    <p class="float-right text-sm">{{templateContentLength(76)}}</p>
                  </base-input>
                </div>
              </div>
              <div class="form-group row mb-0">
                <label class="col-md-2 col-form-label form-control-label text-center">무료수신거부 <span class="text-danger">*</span></label>
                <div class="col-md-10">
                  <base-input placeholder="080-0000-0000"
                              v-model="form.unsubscribeContent"
                              :maxlength="13"
                              name="무료수신거부"
                              :error="_getError('무료수신거부')"
                              v-validate="'required'"
                              @keyup="phoneNoMask"
                  >
                  </base-input>
                </div>
              </div>
              <div class="form-group row mb-0">
                <div class="col-md-2 text-center">
                  <label class="col-form-label form-control-label">
                    버튼
                  </label>
                  <button type="button" class="btn base-button btn-warning btn-sm ml-2" @click.stop.self="addButtons">추가</button>
                </div>
                <div class="col-md-10">
                  <p class="text-danger font-weight-bold mb-0" style="font-size:0.8rem;">- 버튼 등록시 이미지형은 5개, 와이드형은 2개까지 입력 가능합니다.</p>
                  <p class="text-danger font-weight-bold mb-0" style="font-size:0.8rem;">- 버튼 등록시 채널 추가 버튼은 이미지형인 경우 첫번째, 와이드형인 경우 마지막에 위치해야 합니다.</p>
                  <p class="text-danger font-weight-bold" style="font-size:0.8rem;">- 버튼 url 등록시 url_mobile, url_pc 링크에는 'http://', 'https://'가 포함되어야 하며 scheme_ios, scheme_android 링크는 스킴 형태에 맞게 등록해야 합니다. 그렇지 않을 경우 템플릿 등록이 불가합니다.</p>
                  <div class="table-responsive">
                    <table class="table table-bordered">
                      <colgroup>
                        <col width="8%"/>
                        <col width="15%"/>
                        <col width="20%"/>
                        <col/>
                        <col width="16%"/>
                      </colgroup>
                      <thead class="text-center">
                        <tr>
                          <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">순서</th>
                          <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">버튼타입</th>
                          <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">버튼명</th>
                          <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">버튼링크</th>
                          <th scope="col" class="f-s-3" style="background-color: #f6f9fc;">삭제</th>
                        </tr>
                      </thead>
                      <tbody class="text-center">
                        <tr v-for="(item, index) in buttonList" :key="index">
                          <td class="align-middle">{{ index+1 }}</td>
                          <td>
                            <base-input additional-classes="mb-0">
                              <el-select v-model="item.linkType"
                                         filterable
                                         placeholder="버튼타입 선택">
                                <el-option v-for="option in buttonTypeList"
                                           :key="option.value"
                                           :label="option.label"
                                           :value="option.value">
                                </el-option>
                              </el-select>
                            </base-input>
                          </td>
                          <td>
                            <base-input v-if="item.linkType === 'AC'"
                                        additional-classes="mb-0"
                                        value="채널 추가"
                                        readonly
                                        :maxlength="14"
                                        :name="`버튼명${index+1}`"
                                        :error="_getError(`버튼명${index+1}`)"
                                        v-validate="'required|max:14'"
                            ></base-input>
                            <base-input v-else placeholder="버튼명 입력 (14자 이내)"
                                        additional-classes="mb-0"
                                        v-model="item.name"
                                        :maxlength="14"
                                        :name="`버튼명${index+1}`"
                                        :error="_getError(`버튼명${index+1}`)"
                                        v-validate="'required|max:14'"
                            ></base-input>
                          </td>
                          <td>
                            <b class="text-danger" v-if="item.linkType === 'AL'">* Android, iOS, Mobile 중 2가지 이상 입력 필수</b>
                            <base-input
                              v-if="item.linkType === 'AL'"
                              label="Android"
                              label-classes="small-label-type"
                              additional-classes="mt-2"
                              placeholder="scheme android 링크"
                              v-model="item.linkAnd"
                            >
                            </base-input>

                            <base-input
                              v-if="item.linkType === 'AL'"
                              label="iOS"
                              label-classes="small-label-type"
                              placeholder="scheme iOS 링크"
                              v-model="item.linkIos"
                            >
                            </base-input>

                            <base-input
                              v-if="item.linkType === 'AL' || item.linkType === 'WL'"
                              label="Mobile"
                              label-classes="small-label-type"
                              placeholder="http:// 또는 https:// 가 포함된 URL"
                              v-model="item.linkMo"
                            >
                            </base-input>

                            <base-input
                              v-if="item.linkType === 'AL' || item.linkType === 'WL'"
                              label="PC (선택)"
                              label-classes="small-label-type"
                              additional-classes="mb-0"
                              placeholder="http:// 또는 https:// 가 포함된 URL"
                              v-model="item.linkPc"
                            >
                            </base-input>
                          </td>
                          <td class="align-middle">
                            <div>
                              <base-button
                                class="edit"
                                type="success"
                                size="sm"
                                icon
                                @click.native="moveUp(index)">
                                <i class="text-white ni ni-bold-up align-middle"></i>
                              </base-button>
                              <base-button
                                class="edit"
                                type="success"
                                size="sm"
                                icon
                                @click.native="moveDown(index)">
                                <i class="text-white ni ni-bold-down align-middle"></i>
                              </base-button>
                              <base-button
                                class="edit"
                                type="danger"
                                size="sm"
                                icon
                                @click.native="deleteButton(index)">
                                <i class="text-white ni ni-fat-remove align-middle"></i>
                              </base-button>
                            </div>
                          </td>
                        </tr>
                        <tr v-if="buttonList.length === 0">
                          <td colspan="5">버튼 정보가 없습니다.</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from 'element-ui';
import commPaginationMixin from '@/shared/mixins/commPaginationMixin';
import commJsUtilMixin from '@/shared/mixins/commJsUtil';
import commValidatorMixin from '@/shared/mixins/commValidatorMixin';
import DropzoneFileUpload from '@/shared/components/Inputs/DropzoneFileUpload';
import { USER_API_PREFIX, RESULT_CODE } from '@/shared/util/const';
import swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';

export default {
  inject: ['$validator'], // vee-validate를 사용하게 위한 주입 진행
  mixins: [
    commPaginationMixin, commJsUtilMixin, commValidatorMixin
  ],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    DropzoneFileUpload
  },
  filters: {
    templateStatusFilter: value => {
      let name = '';
      switch (value) {
        case 'S':
          name = '중지';
          break;
        case 'A':
          name = '정상';
          break;
        case 'R':
          name = '대기';
          break;
      }
      return name;
    }
  },
  data() {
    return {
      dropzoneOptions: {},
      image :[],
      imageData: null,
      buttonSizeNumber: 0,
      buttonList: [],
      senderKeyList: [],
      buttonTypeList: [
        {
          label: '채널추가',
          value: 'AC'
        },{
          label: '웹링크',
          value: 'WL'
        },{
          label: '앱링크',
          value: 'AL'
        },{
          label: '봇키워드',
          value: 'BK'
        },{
          label: '메시지전달',
          value: 'MD'
        }
      ],
      form: {
        kkoSeqno: null,
        clientId: null,
        channelId: null,
        templateCode: null,
        templateName: null,
        templateContent: null,
        unsubscribeContent: null,
        status: null,
        messageType: null,
        contentType: null,
        imageLink: null,
        regBy: '',
        regDate: null,
        updateBy: '',
        updateDate: null,
        createdAt: null,
        buttonsJson: ''
      },
      templateButtonList: [],
      referer: ''
    };
  },
  created() {
    this.form.kkoSeqno = this.$route.params.seq;
    if(this.$route.query.referer) {
      this.referer = this.$route.query.referer+'?page='+this.$route.query.page+'&perPage='+this.$route.query.perPage+'&condition='+this.$route.query.condition+'&keyword='+this.$route.query.keyword;
    }

    //파일 컴포넌트 설정
    this.setDropzoneOptions();
    // 발신프로필키 목록 조회
    this.getSenderKeyList();
    // 템플릿 정보 조회
    this.getTemplateInfo();
  },
  methods: {
    /**
     * 발신프로필키 목록 조회
     */
    getSenderKeyList() {
      this.$axios.get(`${USER_API_PREFIX}/brandtalk/template/channel`, {
        params: {}
      }).then(res => {
        this.senderKeyList = res.data.result.channelList;
      });
    },
    /**
     * 템플릿 정보 조회
     */
    getTemplateInfo() {
      this.$axios.get(`${USER_API_PREFIX}/brandtalk/template/${this.form.kkoSeqno}`, {
        params: {
        }
      }).then(res => {
        let templateInfo = res.data.result.info;
        this.form.kkoSeqno = templateInfo.kkoSeqno;
        this.form.clientId = templateInfo.clientId;
        this.form.channelId = templateInfo.channelId;
        this.form.templateCode = templateInfo.templateCode;
        this.form.templateName = templateInfo.templateName;
        this.form.templateContent = templateInfo.templateContent;
        this.form.unsubscribeContent = templateInfo.unsubscribeContent;
        this.form.status = templateInfo.status;
        this.form.messageType = templateInfo.messageType;
        this.form.contentType = templateInfo.contentType;
        this.form.imageLink = templateInfo.imageLink;
        this.form.regBy = templateInfo.regBy;
        this.form.regDate = templateInfo.regDate;
        this.form.updateBy = templateInfo.updateBy;
        this.form.updateDate = templateInfo.updateDate;
        this.form.createdAt = templateInfo.createdAt;
        this.imageData = templateInfo.imageData;
        this.form.buttonsJson = templateInfo.buttonsJson;
        if(this.form.buttonsJson) {
          this.buttonList = JSON.parse(this.form.buttonsJson);
        }
      });
    },
    /**
     * 템플릿 정보 수정
     */
    processModify() {
      this._validateBeforeSubmit(this.modify, this.errorCallback);
    },
    /**
     * 수정 로직 실행
     */
    modify() {
      if(this.checkUploadedImage() && !this.checkValidateButtonInfo()) {
        let formData = new FormData();
        formData.append('channelId', this.form.channelId);
        formData.append('messageType', this.form.messageType);
        formData.append('templateName', this.form.templateName);
        formData.append('templateContent', this.form.templateContent);
        formData.append('unsubscribeContent', this.form.unsubscribeContent);
        formData.append('imageLink', this.form.imageLink);
        formData.append('image', this.image[0]);
        let buttonList = this.setTemplateButtonsParam();
        if(buttonList.length > 0) {
          formData.append('buttonsJson', JSON.stringify(buttonList));
        }

        this._swalQuestion({ title:'수정 하시겠습니까?' }, () => {
          this.$axios.put(
            `${USER_API_PREFIX}/brandtalk/template/${this.form.kkoSeqno}`, formData, { headers: { 'Content-Type': 'multipart/form-data' } }
          ).then(res => {
            if (res.data.result.code === RESULT_CODE.SUCCESS) {
              this.notify('default', res.data.result.message);

              // 템플릿 정보 조회
              this.getTemplateInfo();
            } else {
              this.notify('warning', res.data.result.message);
            }
          });
        });
      }
    },
    /**
     * 템플릿 삭제 진행
     */
    processRemove() {
      this._swalQuestion({ title:'삭제 하시겠습니까?' }, () => {
        this.$axios.delete(`${USER_API_PREFIX}/brandtalk/template/${this.form.kkoSeqno}`, {
          params: {
          }
        }).then(res => {
          if (res.data.result.code === RESULT_CODE.SUCCESS) {
            this.notify('default', res.data.result.message);
            this.$router.push(`${this.referer}`);
          } else {
            this.notify('warning', res.data.result.message);
          }
        });
      });
    },
    /**
     * 이미지를 업로드 하였는지 체크
     */
    checkUploadedImage() {
      if(this.image.length > 0) {
        return true;
      } else {
        swal({
          title: `경고`,
          text: '이미지는 필수입니다.',
          buttonsStyling: false,
          confirmButtonClass: 'btn btn-warning',
          type: 'warning',
          width: 400
        });
        return false;
      }
    },
    /**
     * 버튼 정보 유효성 체크
     */
    checkValidateButtonInfo() {
      let isError = false;
      this.buttonList.some((button, idx) => {
        if(button.linkType === 'WL') {
          if(!button.linkMo) {
            swal({
              title: `경고`,
              text: '웹링크의 Mobile 항목은 필수 정보입니다.',
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning',
              type: 'warning',
              width: 400
            });
            isError = true;
          }
        } else if(button.linkType === 'AL') {
          if( (!button.linkAnd && !button.linkIos) || (!button.linkAnd && !button.linkMo) || (!button.linkIos && !button.linkMo) ) {
            swal({
              title: `경고`,
              text: '앱링크는 Android, iOS, Mobile 중 2가지 이상 필수 입력입니다.',
              buttonsStyling: false,
              confirmButtonClass: 'btn btn-warning',
              type: 'warning',
              width: 400
            });
            isError = true;
          }
        }
        return isError;
      });
      return isError;
    },
    /**
     * 버튼 파라미터 정보 세팅
     */
    setTemplateButtonsParam() {
      let templateButtonList = [];
      this.buttonList.forEach((button, idx) => {
        let obj = {};
        obj.name = button.name;
        obj.linkType = button.linkType;
        obj.ordering = idx+1;

        if(button.linkType === 'WL') {
          if(button.linkMo)
            obj.linkMo = button.linkMo;
          if(button.linkPc)
            obj.linkPc = button.linkPc;
        } else if(button.linkType === 'AL') {
          if(button.linkAnd)
            obj.linkAnd = button.linkAnd;
          if(button.linkIos)
            obj.linkIos = button.linkIos;
          if(button.linkMo)
            obj.linkMo = button.linkMo;
          if(button.linkPc)
            obj.linkPc = button.linkPc;
        } else if(button.linkType === 'AC') {
          obj.name = null;
        }
        templateButtonList.push(obj);
      });

      return templateButtonList;
    },
    /**
     * 필수요소 중, 셀렉트박스 및 텍스트박스 값이 없을 경우 invalid 강조표시를 하기 위한 콜백함수
     * '발신프로필키', '템플릿내용' 항목에 대하여 border color를 변경한다.
     */
    errorCallback() {
      this.errors.items.forEach(item => {
        if(item.field === '발신프로필') {
          this.$refs.channelId.$el.getElementsByClassName('el-input__inner')[0].style.borderColor = '#fb6340';
        }
        if(item.field === '템플릿내용') {
          this.$refs.templateContent.style.borderColor = '#fb6340';
        }
      });
    },
    /**
     * 템플릿내용 invalid 강조 및 제거 이벤트
     */
    keyupContent() {
      if(this.errors.has('템플릿내용')) {
        this.$refs.templateContent.style.borderColor = '#fb6340';
      } else {
        this.$refs.templateContent.style.borderColor = '#DCDFE6';
      }
    },
    /**
     * 버튼 추가 이벤트
     */
    addButtons() {
      let isWide = this.form.messageType === 'W';
      let limit = isWide ? 2 : 5;

      if(this.buttonList.length < limit) {
        this.buttonList.push({
          name: '',
          linkType: 'AC',
          linkMo: '',
          linkPc: '',
          linkAnd: '',
          linkIos: ''
        });
      }
    },
    /**
     * 버튼 순서 위로
     * @param rowKey
     */
    moveUp(rowKey) {
      this.buttonList.splice(rowKey - 1, 0, this.buttonList.splice(rowKey, 1)[0]);
    },
    /**
     * 버튼 순서 아래로
     * @param rowKey
     */
    moveDown(rowKey) {
      this.buttonList.splice(rowKey + 1, 0, this.buttonList.splice(rowKey, 1)[0]);
    },
    /**
     * 버튼 제거
     * @param rowKey
     */
    deleteButton(rowKey) {
      this.buttonList.splice(rowKey, 1);
    },
    /**
     * 전 페이지로 이동
     */
    goBack() {
      this.$router.push(this.referer);
    },
    /**
     * 알림 메시지 생성
     * @param type
     */
    notify(type = 'default', message) {
      let notiMsg = message ? message : '일치하는 계정 정보가 없습니다.';
      this.$notify({
        message: notiMsg,
        timeout: 5000,
        icon: 'ni ni-bell-55',
        type
      });
    },
    /**
     * 가로:세로 비율이 2:1 미만 또는 3:4 초과시 업로드 불가합니다.
     * 위와같은 이미지 업로드 정책에 맞는 비율 체크 로직
     */
    calculatorRatio(x, y) {
      let check1 = x > 2*y;
      let check2 = 4*x < 3*y;
      return check1 || check2;
    },
    /**
     * 이미지 파일 검증
     */
    setDropzoneOptions() {
      const $this = this;
      this.dropzoneOptions = {
        autoProcessQueue : false,
        addRemoveLinks : true,
        accept: (file, done) => {
          const fileReader = new FileReader();
          const image = new Image();
          let maxFileSize = 2 * 1024 * 1024;

          fileReader.readAsDataURL(file);

          if(file.size > maxFileSize) {
            $this.$refs.dropzone.dropzone.removeFile(file);
            $this.notify('warning', '이미지 용량이 초과 되었습니다.');
            return false;
          }

          fileReader.onloadend = () => {
            if(fileReader.result) {
              image.onload = () => {
                if(image.width < 500 || this.calculatorRatio(image.width, image.height)) {
                  $this.$refs.dropzone.dropzone.removeFile(file);
                  $this.notify('warning', '이미지 해상도가 부적합 합니다.');
                } else {
                  done();
                }
              };
              image.src = fileReader.result;
            }
          }
        }
      }
    },
    /**
     * 키 이벤트로 핸드폰 번호 형식으로 문자열 변경
     */
    phoneNoMask() {
      this.form.unsubscribeContent = this._autoHyphenPhoneNumber(this.form.unsubscribeContent);
    },
    /**
     * 템플릿 내용 자리수 반환
     * @param maxLength
     * @returns {string}
     */
    templateContentLength(maxLength) {
      let content = this.form.templateContent;
      let contentLength = 0;
      if(content) contentLength = content.length;
      return `${contentLength} / ${maxLength} 자`
    },
    /**
     * vue.js 한글 입력 버그 방지용
     * @param e
     */
    typing (e) {
      this.form.templateContent = e.target.value
    },
  }
};
</script>
<style scoped>
  .no-border-card .card-footer{
    border-top: 0;
  }
  .f-s-3 {
    font-size: 0.75rem;
  }
  .f-s-4 {
    font-size: 1rem;
  }
</style>
